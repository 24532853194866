.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.w-100vw {
  width: 100vw !important;
}
.h-100vh {
  height: 100vh !important;
}
.w-auto {
  width: auto !important;
}
.h-auto {
  height: auto !important;
}
.mxw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.mnw-100vw {
  min-width: 100vw !important;
}
.mh-100vh {
  min-height: 100vh !important;
}
.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-33 {
  width: 33.33% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}
@media (min-width: 370px) {
  .w-3xs-10 {
    width: 10% !important;
  }
  .w-3xs-20 {
    width: 20% !important;
  }
  .w-3xs-25 {
    width: 25% !important;
  }
  .w-3xs-30 {
    width: 30% !important;
  }
  .w-3xs-33 {
    width: 33.33% !important;
  }
  .w-3xs-40 {
    width: 40% !important;
  }
  .w-3xs-50 {
    width: 50% !important;
  }
  .w-3xs-60 {
    width: 60% !important;
  }
  .w-3xs-70 {
    width: 70% !important;
  }
  .w-3xs-75 {
    width: 75% !important;
  }
  .w-3xs-80 {
    width: 80% !important;
  }
  .w-3xs-90 {
    width: 90% !important;
  }
  .w-3xs-100 {
    width: 100% !important;
  }
  .w-3xs-auto {
    width: auto !important;
  }
}
@media (min-width: 480px) {
  .w-2xs-10 {
    width: 10% !important;
  }
  .w-2xs-20 {
    width: 20% !important;
  }
  .w-2xs-25 {
    width: 25% !important;
  }
  .w-2xs-30 {
    width: 30% !important;
  }
  .w-2xs-33 {
    width: 33.33% !important;
  }
  .w-2xs-40 {
    width: 40% !important;
  }
  .w-2xs-50 {
    width: 50% !important;
  }
  .w-2xs-60 {
    width: 60% !important;
  }
  .w-2xs-70 {
    width: 70% !important;
  }
  .w-2xs-75 {
    width: 75% !important;
  }
  .w-2xs-80 {
    width: 80% !important;
  }
  .w-2xs-90 {
    width: 90% !important;
  }
  .w-2xs-100 {
    width: 100% !important;
  }
  .w-2xs-auto {
    width: auto !important;
  }
}
@media (min-width: 640px) {
  .w-xs-10 {
    width: 10% !important;
  }
  .w-xs-20 {
    width: 20% !important;
  }
  .w-xs-25 {
    width: 25% !important;
  }
  .w-xs-30 {
    width: 30% !important;
  }
  .w-xs-33 {
    width: 33.33% !important;
  }
  .w-xs-40 {
    width: 40% !important;
  }
  .w-xs-50 {
    width: 50% !important;
  }
  .w-xs-60 {
    width: 60% !important;
  }
  .w-xs-70 {
    width: 70% !important;
  }
  .w-xs-75 {
    width: 75% !important;
  }
  .w-xs-80 {
    width: 80% !important;
  }
  .w-xs-90 {
    width: 90% !important;
  }
  .w-xs-100 {
    width: 100% !important;
  }
  .w-xs-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-33 {
    width: 33.33% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 1024px) {
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-33 {
    width: 33.33% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
}
@media (min-width: 1280px) {
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-33 {
    width: 33.33% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
}
@media (min-width: 1360px) {
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-33 {
    width: 33.33% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
}
@media (min-width: 1680px) {
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-33 {
    width: 33.33% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
}
.about__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: var(--color-bright-space);
  width: 56px;
  height: 56px;
}
.about__icon [class^="icon-"] {
  line-height: 56px;
}
.about__icon [class^="icon-"]:before {
  color: var(--color-white);
}
.about__icon .icon-smile,
.about__icon .icon-card {
  font-size: 36px;
}
.about__icon .icon-percent {
  font-size: 26px;
}
.about__icon .icon-road {
  font-size: 32px;
}
@media (min-width: 640px) {
  .about__icon {
    width: 64px;
    height: 64px;
  }
  .about__icon [class^="icon-"] {
    line-height: 64px;
  }
  .about__icon .icon-smile,
  .about__icon .icon-card {
    font-size: 42px;
  }
  .about__icon .icon-percent {
    font-size: 32px;
  }
  .about__icon .icon-road {
    font-size: 36px;
  }
}
@media (min-width: 1280px) {
  .about__icon {
    width: 80px;
    height: 80px;
  }
  .about__icon [class^="icon-"] {
    line-height: 80px;
  }
  .about__icon .icon-smile,
  .about__icon .icon-card {
    font-size: 50px;
  }
  .about__icon .icon-percent {
    font-size: 39px;
  }
  .about__icon .icon-road {
    font-size: 44px;
  }
}
.about__header {
  font-size: 20px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .about__header {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (min-width: 1024px) {
  .about__header {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (min-width: 1360px) {
  .about__header {
    font-size: 22px;
    line-height: 28px;
  }
}
.about__text {
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 480px) {
  .about__text {
    font-size: 17px;
    line-height: 23px;
  }
}
@media (min-width: 1280px) {
  .about__text {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (min-width: 1360px) {
  .about__text {
    font-size: 19px;
    line-height: 26px;
  }
}
.about__img {
  background-image: url(./car-photo.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 16px;
}
@media (max-width: 1024px) {
  .about .left-container {
    max-width: 480px;
  }
}
.about .right-container {
  height: 240px;
}
@media (min-width: 370px) {
  .about .right-container {
    height: 320px;
  }
}
@media (min-width: 480px) {
  .about .right-container {
    height: 420px;
  }
}
@media (min-width: 640px) {
  .about .right-container {
    height: 480px;
  }
}
@media (min-width: 768px) {
  .about .right-container {
    height: 580px;
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .about .right-container {
    height: auto;
  }
}
.services__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: auto;
}
.services__icon [class^="icon-"] {
  font-size: 48px;
}
.services__icon [class^="icon-"]:before {
  color: var(--color-sky-yellow);
}
@media (min-width: 640px) {
  .services__icon {
    width: 56px;
  }
  .services__icon [class^="icon-"] {
    font-size: 56px;
  }
}
@media (min-width: 1024px) {
  .services__icon {
    width: 64px;
  }
  .services__icon [class^="icon-"] {
    font-size: 64px;
  }
}
.services__header {
  font-size: 18px;
  line-height: 24px;
}
@media (min-width: 370px) {
  .services__header {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1280px) {
  .services__header {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1360px) {
  .services__header {
    font-size: 22px;
    line-height: 28px;
  }
}
.services__text {
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 640px) {
  .services__text {
    font-size: 17px;
    line-height: 24px;
  }
}
@media (min-width: 1360px) {
  .services__text {
    font-size: 18px;
    line-height: 24px;
  }
}
.services__item {
  padding: 0 0 24px;
  border-bottom: 1px solid var(--color-silver);
}
.services__item:last-child {
  border-bottom: none;
}
@media (min-width: 1280px) {
  .services__item {
    padding: 0 0 32px;
    max-width: 560px;
  }
}
@media (max-width: 1024px) {
  .services .left-container {
    max-width: 480px;
  }
}
@media (max-width: 1024px) {
  .services .right-container {
    max-width: 540px;
  }
}
.how-to .timeline-item__icon.icon-download:before,
.how-to .timeline-item__icon.icon-user:before,
.how-to .timeline-item__icon.icon-roadmap:before,
.how-to .timeline-item__icon.icon-qr-code:before {
  font-size: 28px;
}
@media (max-width: 1024px) {
  .how-to .right-container {
    max-width: 480px;
  }
}
@media (max-width: 1024px) {
  .how-to .left-container {
    max-width: 440px;
  }
}
.gallery .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.gallery .column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}
.gallery .column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  border-radius: 14px;
}
.gallery .column img:hover {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .gallery .column {
    flex: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .gallery .column {
    flex: 100%;
    max-width: 100%;
  }
}
.gallery-fullpage {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}
.gallery-fullpage.display {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 10000;
  cursor: zoom-out;
}
.beta__header {
  font-size: 40px;
  line-height: 48px;
  font-family: var(--font-demibold);
  font-weight: 600;
  word-break: break-word;
}
@media (min-width: 480px) {
  .beta__header {
    font-size: 48px;
    line-height: 54px;
  }
}
@media (min-width: 1280px) {
  .beta__header {
    font-size: 60px;
    line-height: 70px;
  }
}
@media (min-width: 1680px) {
  .beta__header {
    font-size: 72px;
    line-height: 72px;
  }
}
.beta__desc {
  font-size: 18px;
  line-height: 26px;
}
@media (min-width: 768px) {
  .beta__desc {
    font-size: 26px;
    line-height: 38px;
  }
}
@media (min-width: 1280px) {
  .beta__desc {
    font-size: 28px;
    line-height: 42px;
  }
}
.beta__text {
  font-size: 18px;
  line-height: 24px;
}
@media (min-width: 640px) {
  .beta__text {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 768px) {
  .beta__text {
    font-size: 22px;
    line-height: 28px;
  }
}
@media (min-width: 1024px) {
  .beta__text {
    font-size: 22px;
    line-height: 36px;
  }
}
@media (min-width: 1280px) {
  .beta__text {
    font-size: 24px;
    line-height: 38px;
  }
}
.beta .btn {
  width: 220px;
}
.footer {
  margin-bottom: 96px;
}
@media (min-width: 1024px) {
  .footer {
    margin-bottom: 0;
  }
}
.footer__link {
  color: var(--color-graphite);
  transition: 0.3s;
}
.footer__link:visited {
  color: var(--color-space-gray);
}
.footer__link:hover,
.footer__link:hover:visited {
  color: var(--color-bright-space);
}
.footer__label {
  font-size: 13px;
  line-height: 13px;
  opacity: 0.6;
}
.footer__header {
  font-size: 17px;
  line-height: 22px;
}
.footer__phone {
  font-size: 16px;
  line-height: 18px;
}
.footer__icon {
  font-size: 32px;
  line-height: 32px;
}
.label {
  display: inline-block;
  color: var(--color-label);
  letter-spacing: 0.015em;
}
.label--required:after {
  content: '*';
  position: relative;
  display: inline-block;
  color: var(--color-danger);
  font-size: 18px;
  line-height: 0;
  padding-left: 4px;
  top: 0px;
}
.input,
.textarea {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 400;
  transition: 0.3s;
  color: var(--color-space-gray);
}
.textarea {
  height: 100%;
  max-height: 100%;
  resize: vertical;
  line-height: 18px;
  box-sizing: border-box;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.textarea--height-auto {
  height: auto;
}
.input-content:hover > .input,
.input:focus,
.input:hover {
  border-color: var(--color-space-gray);
}
.input-content:hover > .input--error,
.input--error {
  border-color: var(--color-danger);
}
.err-t {
  color: var(--color-danger);
  font-family: var(--font-regular);
  font-weight: 400;
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
}
.input-container {
  position: relative;
}
.input-container--col .label {
  margin-bottom: 4px;
}
.input-container--md .label {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}
.input-container--md .input-content .input {
  font-size: 15px;
  line-height: 15px;
  height: 40px;
  padding: 0 12px;
}
.input-container--md .input-content .input.textarea {
  height: auto;
}
.timeline {
  position: relative;
  counter-reset: section;
}
.timeline--sm .timeline-item {
  padding-bottom: 40px;
}
.timeline--sm .timeline-item:before {
  left: 27px;
}
.timeline--sm .timeline-item__icon:before {
  width: 56px;
  height: 56px;
  font-size: 32px;
  line-height: 56px;
}
.timeline--sm .timeline-item__icon:after {
  width: 72px;
  height: 72px;
  left: -8px;
  top: -8px;
}
.timeline--sm .timeline-item__text {
  font-size: 17px;
  line-height: 22px;
  padding-top: 4px;
}
.timeline-item {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  column-gap: 16px;
}
.timeline-item:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  background-color: var(--color-bright-space);
}
.timeline-item:last-child {
  padding-bottom: 0;
}
.timeline-item:last-child:before {
  height: 0;
}
.timeline-item__icon {
  position: relative;
}
.timeline-item__icon:before {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--color-white);
  background-color: var(--color-bright-space);
  z-index: 2;
}
.timeline-item__icon:after {
  content: "";
  display: block;
  position: absolute;
  background-color: var(--color-white);
  z-index: 1;
}
.timeline-item__text {
  font-size: 20px;
  line-height: 28px;
  width: 100%;
}
@media (min-width: 640px) {
  .timeline-item {
    column-gap: 24px;
  }
}
.swiper-button-next,
.swiper-button-prev {
  width: 44px;
  height: 44px;
  background: #f4f4f4;
  background: linear-gradient(100deg, #f4f4f4 20%, #f8f8f8 30%, #faf4f4 40%, #ffffff 50%, #f8f8f8 60%, #f8f8f8 70%, #f4f4f4 80%);
  border-radius: 50%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #AAABAD !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
  color: #7B2AFF !important;
}
.swiper-button-next:active:after,
.swiper-button-prev:active:after {
  color: #AAABAD !important;
}
@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}
@media (min-width: 1024px) {
  .swiper-pagination {
    left: 10% !important;
    bottom: 60px !important;
  }
}
.swiper-pagination-bullet {
  background: #D9D9D9 !important;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #7B2AFF !important;
}
.slider-image {
  max-width: 56px;
  height: auto;
}
.slider-text {
  display: block;
  min-width: 200px;
}
.link-arrow {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  flex-shrink: 0;
  z-index: 5;
}
.link-arrow:hover {
  transform: scale(1.1);
}
.link-arrow svg {
  width: 16px;
  height: 16px;
  fill: var(--color-white);
}
.link-arrow--bottom .icon-arrow-back {
  transform: rotatez(0deg);
}
.link-arrow--left .icon-arrow-back {
  transform: rotatez(90deg);
}
.link-arrow--dark {
  color: var(--color-white);
  background-color: var(--color-white);
}
.link-arrow--white {
  color: var(--color-white);
  background-color: var(--color-black);
}
@media (min-width: 1024px) {
  .link-arrow {
    width: 64px;
    height: 64px;
  }
  .link-arrow svg {
    width: 20px;
    height: 20px;
  }
}
